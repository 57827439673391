<template>
  <div class="link-view">
    <notifications group="copy" position="top right">
      <template slot="body" slot-scope="props">
        <div class="vue-notification-template vue-notification custom">
          <img :src="props.item.text" alt="i" />
          <div class="notification-title">
            {{ props.item.title }}
          </div>
        </div>
      </template>
    </notifications>
    <div class="link-view__input">
      <div class="icon">
        <img src="@/assets/img/icons/deep-settings/link-copy.svg" alt="copy" />
      </div>
      <input type="text" readonly :value="link" ref="link" class="input" />
    </div>
    <app-button color="red" @click="copy">копировать ссылку</app-button>
  </div>
</template>

<script>
import AppButton from '@/components/elements/AppButton'
export default {
  props: {
    urlHash: {
      type: String,
      required: true
    }
  },
  name: 'LinkView',
  components: { AppButton },
  methods: {
    copy() {
      const copyCode = this.$refs.link
      copyCode.select()
      document.execCommand('copy')
      this.$notify({
        group: 'copy',
        title: 'ссылка скопирована в буфер',
        text: require('@/assets/img/notify.svg')
      })
    }
  },
  computed: {
    link() {
      const domainName = `${location.origin}/${this.$i18n.locale}`
      return `${domainName}/result/?object=${this.urlHash}`
    }
  }
}
</script>

<style scoped lang="sass">
.link-view
  min-width: rem(800)
  display: grid
  grid-template-columns: 1fr auto

  &__input
    border: rem(1) solid $cool_A
    border-radius: rem(4) 0 0 rem(4)
    display: grid
    border-right: 0
    grid-template-columns: auto 1fr
    .input
      font-family: $main-font
      border: none
      padding: rem(20) rem(20) rem(20) 0
      overflow: hidden
      text-overflow: ellipsis
      color: $black_A
      font-size: rem(14)
      font-weight: normal
      font-style: normal
      line-height: rem(16)
      outline: none
      cursor: default
    .icon
      width: rem(58)
      height: 100%
      display: flex
      justify-content: center
      align-items: center
  .app-button
    border-radius: 0 rem(4) rem(4) 0
</style>
